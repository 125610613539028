
.container {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1000px;
  box-sizing: border-box;
}

#fh5co-logo {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  float: left;
  position: relative;
  z-index: 102;
  display: block;
}

#fh5co-header {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

#fh5co-main-nav {

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  position: relative;
  margin-top: 24px;
  text-align: center;
}

#fh5co-nav {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  display: block;
  position: relative;
  width: 100%;
}

.fh5co {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 24px;
  padding: 0;
  margin: 0 0 7px 0;
  list-style: none;
  display: inline-block;
}

.fh5co-intro {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  margin-bottom: 1em;
}

#row {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
  margin-left: -15px;
  margin-right: -15px;
}

h3 {
  display: block;
}

#fh5co-intro h3 {
  font-size: 40px;
  line-height: 52px;
  padding: 0;
  font-family: "PT Mono", sans-serif;
  font-weight: 400;
  color: #000000;
}


#fh5co-intro h1 {
  font-weight: bolder;
  font-size: 72px;
  color: #000000;
  line-height: 1.2;
}
#fh6co-intro h1 {
  font-weight: bolder;
  font-size: 73px;
  color: #000000;
  line-height: 1.2;
}

#fh5co-main-nav ul > li > a {
  padding: 5px 0;
  margin: 0 10px;
  color: #7c7c7c;
  border-bottom: 2px solid transparent;

  a {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #1d2dfd;
    border-bottom: 2px solid transparent;
    text-decoration: none;
  }
}

::selection {
  color: #ffffff;
  background: #1d2dfd;
}

#fh5co-main-nav ul > li {
  padding: 0;
  margin: 0 0 7px 0;
  list-style: none;
  display: inline-block;
  text-align: -webkit-match-parent;
}

#fh5co-main-nav ul > li > a {
  padding: 5px 0;
  margin: 0 10px;
  color: #7c7c7c;
  border-bottom: 2px solid transparent;
}

a {
  color: #1d2dfd;
  text-decoration: none;
}

a {
  background-color: transparent;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

kullanıcı aracısı stil sayfası
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

kullanıcı aracısı stil sayfası
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

#fh5co-main-nav ul > li {
  padding: 0;
  margin: 0 0 7px 0;
  list-style: none;
  display: inline-block;
}

li {
  text-align: -webkit-match-parent;
}

#fh5co-main-nav ul {
  padding: 0;
  margin: 0 0 7px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  text-align: center;
}

ul, ol {
  padding-left: 15px;
  line-height: 24px;
}

ul, ol {
  padding-left: 15px;
  line-height: 26px;
  margin-left: 15px;
}

ul {
  list-style-type: disc;
}

form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}

.textinput {
  width: 99%;
  min-height: 150px;
  resize: none;
  margin-left: 1%;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  overflow: auto;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

#fh5co-form {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "PT Mono", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #7b7b7b;
  font-weight: 300;
  letter-spacing: .05em;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  line-height: normal;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

input:not([type="image" i], [type="range" i], [type="checkbox" i], [type="radio" i]) {
  overflow-clip-margin: 0px !important;
  overflow: clip !important;
}

* {
  box-sizing: border-box;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

h2, .h2 {
  font-size: 50px;
  line-height: 68px;
}

h2, .h2 {
  margin: 0 0 20px 0;
  padding: 0;
  font-family: "PT Mono", sans-serif;
  font-weight: 400;
  color: #000000;
}

h2, .h2 {
  font-size: 50px;
}

h6, .h6 {
  font-size: 16px;
  margin: 0 0 20px 0;
  padding: 0;
  font-family: "PT Mono", sans-serif;
  font-weight: 400;
  color: #000000;
}

p, img {
  margin: 0 0 20px 0;
}

img {
  vertical-align: middle;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  
}
input[type="text" i] {
  padding-block: 1px;
  padding-inline: 2px;
}
button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

#fh5co-intro .fh5co-intro-sub {
  margin: 2em 0;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .col-lg-2 {
    width: 16.66667%;
    float: left;
  }
}
#fh5co-footer .fh5co-footer-social li a {
  color: #7c7c7c;
  padding: 4px 0;
  margin: 0 10px 0 0;
}
#fh5co-footer .fh5co-footer-social li {
  display: inline;
  font-size: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}
#fh5co-footer .fh5co-footer-social {
  padding: 0;
  margin: 0 0 2em 0;
}
#fh5co-footer {
  padding-bottom: 2em;
}
#fh5co-footer {
  padding-bottom: 2em;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}


